import {
  classes as categoriesBreadcrumbsStylable,
  st as categoriesBreadcrumbsStyles,
} from './CategoriesBreadcrumbs.st.css';
import React from 'react';
import {Breadcrumbs} from 'wix-ui-tpa';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';
import {DataHook} from '../../../category/components/CategoriesApp/CategoriesApp';
import classNames from 'classnames';

export const CategoriesBreadcrumbs: React.FunctionComponent<IGalleryGlobalProps> = withGlobals(
  (props: IGalleryGlobalProps) => {
    const {breadcrumbsHistory, shouldShowMobile} = props.globals;
    const classes = classNames(
      categoriesBreadcrumbsStyles(categoriesBreadcrumbsStylable.breadcrumbsWrapper, {
        mobile: shouldShowMobile,
      })
    );
    return (
      <div data-hook={DataHook.Breadcrumbs} className={classes}>
        <Breadcrumbs
          items={breadcrumbsHistory}
          className={categoriesBreadcrumbsStylable.breadcrumbs}
          showTrailOnMobileMode={true}
        />
      </div>
    );
  }
);
