import {Filters} from '../../../gallery/components/Filters/Filters';
import React from 'react';
import {IGlobals} from '../../../gallery/galleryGlobalStrategy';
import {DataHook} from '../../../category/components/CategoriesApp/CategoriesApp';
import classNames from 'classnames';
import s from './SideFilters.scss';

export const SideFilters = ({clearFilters, shouldShowClearFilters, shouldShowMobile, shouldShowSort}: IGlobals) => {
  const isDesktop = !shouldShowMobile;
  const shouldRenderDesktopSort = isDesktop && shouldShowSort;

  const className = classNames(s.filters, {
    [s.withSort]: shouldRenderDesktopSort,
  });

  return (
    <aside className={className} data-hook={DataHook.SideFilters}>
      <Filters shouldShowClearFiltersButton={shouldShowClearFilters} clearFilters={clearFilters} />
    </aside>
  );
};
