/* istanbul ignore file: todo add infinite scroll tests @guyse */

/* eslint-disable @typescript-eslint/unbound-method */
import React from 'react';
import autobind from 'auto-bind-es5';
import imagesLoaded from 'imagesloaded';

import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {DataHook as ProductImagesDataHook} from '../../../common/components/ProductItem/ProductImageOLD/ProductImageOLD';
import {EmptyGallery} from './EmptyGallery/EmptyGallery';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';
import {LoadMoreButton} from './LoadMoreButton/LoadMoreButton';
import {GridType, LoadMoreType} from '../../../types/galleryTypes';
import {withGlobals} from '../../../globalPropsContext';
import {DEFAULT_MOBILE_PRODUCTS_COUNT} from '../../../constants';
import {ProductListGrid, ProductListGridDataHook} from './ProductListGrid';
import {ProductItemWithGlobals} from '../../../common/components/ProductItem/ProductItem';
import {Pagination, PaginationProps} from './Pagination/Pagination';
import {Loader} from '../../../common/components/Loader/Loader';
import {hasScrollReachedElementBottom} from './utils/infiniteScrollUtils';
import {scrollTo} from '@wix/wixstores-client-core/dist/es/src/utils/scrollTo';
import {PaginationLinksForSeo} from './PaginationLinksForSeo/PaginationLinksForSeo';
import stylesParams from '../../../components/GridGallery/stylesParams';
import {ProductMediaDataHook} from '../../../common/components/ProductItem/ProductMedia/ProductMedia';

export interface ProductListProps extends IGalleryGlobalProps, IProvidedTranslationProps {
  hasFilters: boolean;
}

interface ProductListState {
  inBrowser: boolean;
  firstVisiblePage: number;
}

class ProductListComp extends React.Component<ProductListProps, ProductListState> {
  private imagesLoaded = false;
  private isInteractive = false;
  private focusedAt: number = -1;
  private scrollAfterUpdate = false;
  private loadMoreCalled = false;
  private loadPreviousCalled = false;
  private previousFirstItem: Element;
  private previousFirstItemOffsetTop: number;
  private readonly SCROLL_EVENT_NAME = 'scroll';
  private componentRef: HTMLDivElement;
  private readonly refCallback = (elem) => {
    this.componentRef = elem;
  };

  public constructor(props: ProductListProps) {
    super(props);
    this.state = {
      inBrowser: false,
      firstVisiblePage: props.globals.currentPage,
    };
    autobind(this);
  }

  public componentDidMount(): void {
    const {loadMoreType, renderSEOTags, setTotalPages, experiments} = this.props.globals;
    const {newUiTpaImage, isTotalPagesCalculatedInStore} = this.props.globals.experiments;

    if (loadMoreType === LoadMoreType.INFINITE) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      window.addEventListener(this.SCROLL_EVENT_NAME, this.onScroll);
    }

    if (!isTotalPagesCalculatedInStore) {
      setTotalPages(this.totalPages);
    }

    if (experiments.isPaginationForSeoEnabled) {
      renderSEOTags();
    }

    this.setState({inBrowser: true}, () => {
      const dataHook = newUiTpaImage ? ProductMediaDataHook.Images : ProductImagesDataHook.Images;
      imagesLoaded(document.querySelectorAll(`[data-hook="${dataHook}"]`), () => {
        this.imagesLoaded = true;
        this.reportLoad();
      });
    });
  }

  private reportLoad() {
    if (this.props.globals.isInteractive && this.imagesLoaded) {
      this.props.globals.appLoadBI.loaded();
    }
  }

  public componentDidUpdate(prevProps: IGalleryGlobalProps) {
    if (!this.isInteractive && this.props.globals.isInteractive) {
      this.isInteractive = true;
      /* istanbul ignore next: hard to test it */
      this.props.globals.updateLayout && this.props.globals.updateLayout();
      this.reportLoad();
    }

    if (this.scrollAfterUpdate) {
      this.scrollToTop();
      this.scrollAfterUpdate = false;
    }

    if (this.loadPreviousCalled) {
      if (this.previousFirstItem) {
        const currentTopPositionOfWhatWasFirstItemPriorLoadPrevious =
          window.pageYOffset + this.previousFirstItem.getBoundingClientRect().top;
        scrollTo({
          top: currentTopPositionOfWhatWasFirstItemPriorLoadPrevious - this.previousFirstItemOffsetTop,
          left: 0,
          smooth: false,
        });
      }
      this.loadPreviousCalled = false;
    }

    this.focusedAt = prevProps.globals.focusedProductIndex;
    this.loadMoreCalled = false;
  }

  public componentWillUnmount() {
    const {loadMoreType} = this.props.globals;

    if (loadMoreType === LoadMoreType.INFINITE) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      window.removeEventListener(this.SCROLL_EVENT_NAME, this.onScroll);
    }
  }

  public render() {
    const {products} = this.props.globals;

    return (
      <section data-hook="product-list" ref={this.refCallback} aria-label={this.props.t('galleryRegionSR')}>
        {products.length === 0 ? this.getEmptyList() : this.getProductList()}
      </section>
    );
  }

  private get isMobileOverride() {
    return this.props.globals.shouldShowMobile;
  }

  private get forceSingleColumn() {
    return this.isMobileOverride && this.props.globals.isHorizontalLayout;
  }

  /**
   * @deprecated its calculated in galleryStore
   */
  private get maxProductsPerPage(): number {
    const {shouldUseAutoGridProductsCount, isGalleryRowsAndColsWereModified, styles} = this.props.globals;

    const gallery_productsCount = styles.get(stylesParams.gallery_productsCount);
    const galleryColumns = styles.get(stylesParams.galleryColumns);
    const galleryRows = styles.get(stylesParams.galleryRows);

    switch (true) {
      case shouldUseAutoGridProductsCount:
        return gallery_productsCount;
      case this.isMobileOverride && !isGalleryRowsAndColsWereModified:
        return DEFAULT_MOBILE_PRODUCTS_COUNT;
      default:
        return galleryColumns * galleryRows;
    }
  }

  /**
   * @deprecated Use totalPages of galleryStore
   */
  private get totalPages(): number {
    const {totalProducts} = this.props.globals;
    return Math.ceil(totalProducts / this.maxProductsPerPage);
  }

  private getEmptyList() {
    return <EmptyGallery hasFilters={this.props.hasFilters} />;
  }

  private getFocusableItemIndex() {
    const {isFirstPage, focusedProductIndex} = this.props.globals;
    const prevFocusedProductIndex = this.focusedAt;

    if (!isFirstPage && focusedProductIndex !== prevFocusedProductIndex) {
      return this.props.globals.focusedProductIndex;
    }

    return -1;
  }

  private getNumberOfVisibleProducts(): number {
    if (this.gridType() === GridType.AUTO && this.props.globals.isFirstPage) {
      const {styles} = this.props.globals;
      return styles.get(stylesParams.gallery_productsCount);
    }

    if (this.props.globals.isFirstPage) {
      return this.maxProductsPerPage;
    }

    return this.props.globals.products.length;
  }

  private gridType() {
    return this.props.globals.isAutoGrid ? GridType.AUTO : GridType.MANUAL;
  }

  private getProductList() {
    const {products, styles, loadMoreType, experiments} = this.props.globals;
    const {firstVisiblePage} = this.state;
    let startOfCurrentPageItem = 0;

    if (loadMoreType !== LoadMoreType.PAGINATION && experiments.isPaginationForSeoEnabled) {
      startOfCurrentPageItem = this.maxProductsPerPage * (firstVisiblePage - 1);
    }

    const nextProducts = products.slice(startOfCurrentPageItem, this.getNumberOfVisibleProducts());
    const productSize = styles.get(stylesParams.gallery_productSize);

    return (
      <div>
        {experiments.isPaginationForSeoEnabled && this.renderLoadPrevious()}
        <ProductListGrid
          products={nextProducts}
          isMobileOverride={this.isMobileOverride}
          forceSingleColumn={this.forceSingleColumn}
          gridType={this.gridType()}
          renderKey={String(productSize)}
          focusAt={this.getFocusableItemIndex()}
          ProductItem={ProductItemWithGlobals}
        />
        {this.renderPaginationLinksForSeo()}
        {this.renderLoadMore()}
      </div>
    );
  }

  private async onScroll() {
    const {
      globals: {productsRequestInProgress},
    } = this.props;

    if (this.loadMoreCalled || productsRequestInProgress || !this.shouldShowLoadMore()) {
      return;
    }

    const productListElementRect = this.componentRef.getBoundingClientRect();
    const bodyRect = document.body.getBoundingClientRect();

    const hasScrollReachedProductsListBottom = hasScrollReachedElementBottom(
      productListElementRect,
      bodyRect,
      document.documentElement.scrollTop,
      window.innerHeight
    );

    if (hasScrollReachedProductsListBottom) {
      this.loadMoreCalled = true;
      await this.loadMore();
    }
  }

  private renderPaginationLinksForSeo() {
    const {loadMoreType, currentPage, nextPrevLinks, experiments, totalPages} = this.props.globals;
    if (!experiments.isPaginationForSeoEnabled || loadMoreType === LoadMoreType.PAGINATION) {
      return;
    }

    return (
      <PaginationLinksForSeo
        totalPages={experiments.isTotalPagesCalculatedInStore ? totalPages : this.totalPages}
        currentPage={currentPage}
        nextPrevLinks={nextPrevLinks}
      />
    );
  }

  private renderLoadPrevious() {
    return this.shouldShowLoadPrevious() && <LoadMoreButton onClick={this.loadPrevious} isLoadNext={false} />;
  }

  private renderLoadMore() {
    const {loadMoreType} = this.props.globals;

    switch (loadMoreType) {
      case LoadMoreType.PAGINATION:
        return this.renderPagination();
      case LoadMoreType.INFINITE:
        return this.renderInfiniteScrollLoader();
      case LoadMoreType.BUTTON:
      default:
        return this.shouldShowLoadMore() && this.getLoadMoreButton();
    }
  }

  private shouldShowLoadPrevious(): boolean {
    const {loadMoreType} = this.props.globals;
    return this.state.firstVisiblePage > 1 && loadMoreType !== LoadMoreType.PAGINATION;
  }

  private shouldShowLoadMore(): boolean {
    const {
      globals: {isFirstPage, hasMoreProductsToLoad, totalProducts},
    } = this.props;

    if (isFirstPage) {
      return this.maxProductsPerPage < totalProducts;
    }
    return hasMoreProductsToLoad;
  }

  private getLoadMoreButton() {
    return <LoadMoreButton onClick={this.loadMore} isLoadNext={true} />;
  }

  private async loadMore() {
    await this.props.globals.loadMoreProducts(this.getNumberOfVisibleProducts(), this.maxProductsPerPage);
  }

  private loadPrevious() {
    this.previousFirstItem = this.componentRef.querySelector(`[data-hook="${ProductListGridDataHook.Item}"]`);

    if (this.previousFirstItem) {
      this.previousFirstItemOffsetTop = this.previousFirstItem.getBoundingClientRect().top;
      this.loadPreviousCalled = true;
    }

    this.setState({firstVisiblePage: this.state.firstVisiblePage - 1});
  }

  private scrollToTop() {
    scrollTo({
      top: this.componentRef.getBoundingClientRect().top + window.pageYOffset - 50,
      left: 0,
      smooth: true,
    });
  }

  private renderPagination() {
    const {
      currentPage,
      totalProducts,
      handlePagination,
      paginationMode,
      linkForAllPages,
      experiments,
      totalPages,
      styles,
    } = this.props.globals;

    const gallery_paginationFirstLastArrows = styles.get(stylesParams.gallery_paginationFirstLastArrows);
    const showFirstLastNavButtons = gallery_paginationFirstLastArrows && totalProducts <= 10_000;

    const props: PaginationProps = {
      currentPage,
      paginationMode,
      totalPages: experiments.isTotalPagesCalculatedInStore ? totalPages : this.totalPages,
      showFirstLastNavButtons,
      handlePagination: (page) => {
        this.scrollAfterUpdate = true;
        handlePagination(page);
      },
      linkForAllPages,
      isPaginationForSeoEnabled: experiments.isPaginationForSeoEnabled,
    };

    return <Pagination {...props} />;
  }

  private renderInfiniteScrollLoader() {
    const {
      globals: {productsRequestInProgress},
    } = this.props;

    return productsRequestInProgress && <Loader />;
  }
}

export const ProductList = withGlobals(withTranslations()(ProductListComp));
