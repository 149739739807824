/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/button-has-type */
import React from 'react';
import s from './CategoryTree.scss';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../gallery/galleryGlobalStrategy';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {CategoryFilter} from '../CategoryFilter/CategoryFilter';
import {FilterModel, FilterType} from '../../../types/galleryTypes';

export enum DataHook {
  Title = 'category-tree-title',
  Content = 'category-tree-content',
  Container = 'category-tree-container',
}

export const CategoryTree: React.FunctionComponent<IGalleryGlobalProps & IProvidedTranslationProps> = withGlobals(
  withTranslations()(({t, globals}: IGalleryGlobalProps & IProvidedTranslationProps) => {
    const {filterModels} = globals;
    const collectionFilterModel = filterModels.find(
      (filterModel: FilterModel) => filterModel.filterType === FilterType.COLLECTION
    );
    return (
      <div className={s.container} data-hook={DataHook.Container}>
        <h2 data-hook={DataHook.Title} className={s.title}>
          {t('categoryTreeTitle')}
        </h2>
        <div data-hook={DataHook.Content} className={s.content}>
          <CategoryFilter
            selected={collectionFilterModel.activeOptions}
            filterId={collectionFilterModel.filterId}
            options={collectionFilterModel.options.map((option) => ({
              id: option.key,
              name: option.value,
            }))}
          />
        </div>
      </div>
    );
  })
);
