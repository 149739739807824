import * as React from 'react';
import {ChevronRight} from '../../../../../icons/dist';
import s from './Arrow.scss';
import classNames from 'classnames';
import {withGlobals} from '../../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../../../gallery/galleryGlobalStrategy';
import stylesParams from '../../../../../components/GridGallery/stylesParams';

export enum ArrowDirection {
  RIGHT = 'right',
  LEFT = 'left',
}

export interface ArrowProps extends IGalleryGlobalProps {
  onClick(): void;
  onKeyPress(): void;
  direction: ArrowDirection;
  ariaLabel: string;
  className?: string;
  dataHook: string;
}

class ArrowWithoutGlobals extends React.Component<ArrowProps> {
  private readonly onClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    this.props.onClick();
  };

  private readonly onKeyPress = (event) => {
    event.stopPropagation();
    event.preventDefault();
    this.props.onKeyPress();
  };

  public render() {
    const {direction, className, dataHook, ariaLabel} = this.props;

    const {styles} = this.props.globals;
    const imageCarouselArrowSize = styles.get(stylesParams.gallery_imageCarouselArrowSize).value;

    return (
      <button
        data-hook={dataHook}
        type={'button'}
        aria-label={ariaLabel}
        className={classNames(s.arrow, s[direction], className, s[imageCarouselArrowSize])}
        onClick={this.onClick}
        onKeyPress={this.onKeyPress}>
        <ChevronRight className={s.chevron} />
      </button>
    );
  }
}
export const Arrow = withGlobals(ArrowWithoutGlobals);
