import React from 'react';
import {initContext, Strategy, withGlobalsProvider} from './globalPropsContext';

export interface IGetWrappedComponentOptions {
  Component: React.ComponentType;
  globalPropsStrategy: Strategy<any>;
  sliderGallery?: boolean;
}

export function getWrappedComponent({Component, globalPropsStrategy, sliderGallery}: IGetWrappedComponentOptions) {
  initContext(globalPropsStrategy);
  return withGlobalsProvider(Component, sliderGallery);
}
